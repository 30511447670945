/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 4, 2020 */



@font-face {
    font-family: 'open_sansbold';
    src: url('opensans-bold-webfont.woff2') format('woff2'),
         url('opensans-bold-webfont.woff') format('woff'),
         url('opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sansbold_italic';
    src: url('opensans-bolditalic-webfont.woff2') format('woff2'),
         url('opensans-bolditalic-webfont.woff') format('woff'),
         url('opensans-bolditalic-webfont.svg#open_sansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sansextrabold';
    src: url('opensans-extrabold-webfont.woff2') format('woff2'),
         url('opensans-extrabold-webfont.woff') format('woff'),
         url('opensans-extrabold-webfont.svg#open_sansextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sansextrabold_italic';
    src: url('opensans-extrabolditalic-webfont.woff2') format('woff2'),
         url('opensans-extrabolditalic-webfont.woff') format('woff'),
         url('opensans-extrabolditalic-webfont.svg#open_sansextrabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sansitalic';
    src: url('opensans-italic-webfont.woff2') format('woff2'),
         url('opensans-italic-webfont.woff') format('woff'),
         url('opensans-italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sanslight';
    src: url('opensans-light-webfont.woff2') format('woff2'),
         url('opensans-light-webfont.woff') format('woff'),
         url('opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sanslight_italic';
    src: url('opensans-lightitalic-webfont.woff2') format('woff2'),
         url('opensans-lightitalic-webfont.woff') format('woff'),
         url('opensans-lightitalic-webfont.svg#open_sanslight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sansregular';
    src: url('opensans-regular-webfont.woff2') format('woff2'),
         url('opensans-regular-webfont.woff') format('woff'),
         url('opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sanssemibold';
    src: url('opensans-semibold-webfont.woff2') format('woff2'),
         url('opensans-semibold-webfont.woff') format('woff'),
         url('opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sanssemibold_italic';
    src: url('opensans-semibolditalic-webfont.woff2') format('woff2'),
         url('opensans-semibolditalic-webfont.woff') format('woff'),
         url('opensans-semibolditalic-webfont.svg#open_sanssemibold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
	font-family: 'montserratbold';
	src: url('montserrat-bold-webfont.woff2') format('woff2'),
		 url('montserrat-bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}




@font-face {
	font-family: 'montserratregular';
	src: url('montserrat-regular-webfont.woff2') format('woff2'),
		 url('montserrat-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}

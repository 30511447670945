@import url("../fonts/open-sans/stylesheet.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:wght@300&display=swap");
$bgcolor: #fafafa;
$fontsize: 18px;
$Montserrat: "Montserrat", sans-serif;

img, video {
	max-width: 100%;
}

@media (max-width: 1140px) {
	.container {
		max-width: 100%;
	}
}

@media (min-width: 1600px) {
	.container {
		max-width: 1600px;
		width: 100%;
	}
}

@media (min-width: 1440px) {
	.container {
		max-width: 1440px;
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
		width: 100%;
	}
}

@media (min-width: 1360px) {
	.container {
		max-width: 1360px;
		width: 100%;
	}
}

/* Use the variables */
body {
	background-color: $bgcolor;
	color: #666;
	font-size: $fontsize;
	font-family: "open_sansregular";
	font-weight: normal;
	overscroll-behavior: none;
}

/* end the variables */

@mixin btn-blue {
	color: #fff;
	background-color: #1d61f6;
	background-image: linear-gradient(226deg, #3e9afb 0%, #1553da 100%);
	min-height: 50px;
	text-align: center;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.8s;
	line-height: 30px;
	font-size: 20px;
	border: 1px solid #1d61f6;

	&:hover {
		background-color: #1d61f6;
		box-shadow: 0 3px 15px 0 rgba(0, 153, 255, 0.4);
		transform: translateY(-3px);
		transition: all 0.8s;
	}

	&:focus {
		background-color: #1d61f6;
		box-shadow: 0 3px 15px 0 rgba(0, 153, 255, 0.4);
		transform: translateY(-3px);
	}
}


.dropdown-item {
	background-color: #fff;
	color: #333;
	transition: all 0.3s;

	&:hover {
		background-color: #006586;
		transition: all 0.3s;
		color: #fff;
	}
}

/* header section */

// extra large device
@media (min-width: 2560px) {

	.fixed-top {
		.logo-heder {
			position: fixed;
			padding-left: 0px;
			transform: translateY(0vh);
			left: 20;
			top: 8px;
			// transition: all 0.6s ease-in-out;
			transform-origin: top left;
			transition: all 0.3s ease-in-out;
			margin: unset;
			width: 100%;

			.container {
				max-width: 100% !important;
				width: auto;
			}

			a {
				margin-left: 0 !important;

				img {
					width: 180px !important;
					height: 40px !important;
				}
			}
		}
	}

	.logo-heder {
		position: absolute;
		top: unset;
		left: unset;
		padding-left: 125px;
		width: 100%;
		margin: 0 auto;
		transform: translateY(14vh);

		a {
			padding: 0 !important;

			img {
				width: 600px !important;
				height: 150px !important;
				transition: all 0.3s ease-in-out;
			}
		}
	}

}

// i mac or large device
@media (max-width: 2560px) and (min-width: 1921px) {
	.logo-heder {
		position: absolute;
		top: unset;
		left: unset;
		padding-left: 125px;
		width: 100%;
		margin: 0 auto;
		transform: translateY(14vh);

		a {
			padding: 0 !important;

			img {
				width: 600px !important;
				height: 150px !important;
				transition: all 0.3s ease-in-out;
			}
		}
	}

	.fixed-top {
		.logo-heder {
			position: fixed;
			padding-left: 0px;
			transform: translateY(0vh);
			left: 20;
			top: 8px;
			// transition: all 0.6s ease-in-out;
			transform-origin: top left;
			transition: all 0.3s ease-in-out;
			margin: unset;
			width: 100%;

			.container {
				max-width: 100% !important;
				width: auto;
			}

			a {
				margin-left: 0 !important;

				img {
					width: 180px !important;
					height: 40px !important;
				}
			}
		}
	}
}

@media (max-width: 1920px) and (min-width: 1200px) {
	.logo-heder {
		position: absolute;
		top: unset;
		left: unset;
		padding-left: 125px;
		width: 100%;
		margin: 0 auto;
		transform: translateY(14vh);

		a {
			padding: 0 !important;

			img {
				width: 600px !important;
				height: 150px !important;
				transition: all 0.3s ease-in-out;
			}
		}
	}

	.fixed-top {
		.logo-heder {
			position: fixed;
			padding-left: 0px;
			transform: translateY(0vh);
			left: 20;
			top: 8px;
			transform-origin: top left;
			transition: all 0.3s ease-in-out;
			margin: unset;
			width: 100%;

			.container {
				max-width: 100% !important;
				width: auto;
			}

			a {
				margin-left: 0 !important;

				img {
					width: 180px !important;
					height: 40px !important;
				}
			}
		}
	}
}

@media (max-width: 1920px) and (min-width: 1440px) {
	// .header .navbar .navbar-brand {
	// 	margin-left: 160px;
	// }

	.middle-section {

		.featured-section {
			.featured-inner {
				top: -2em !important;
			}
		}

		// .hero-banner-section {
		// 	.item {
		// 		.slide-owl {
		// 			.banner-caption {
		// 				.caption-box {
		// 					margin-left: 140px;
		// 				}
		// 			}
		// 		}
		// 	}

		// 	.owl-dots {
		// 		bottom: 250px !important;
		// 	}
		// }
	}
}

@media (min-width: 1919px) {
	.logo-heder {
		position: absolute;
		top: unset;
		left: unset;
		padding-left: 290px;
		width: 100%;
		margin: 0 auto;
		transform: translateY(17vh);

		a {
			padding: 0 !important;

			img {
				width: auto !important;
				height: 140px !important;
				transition: all 0.3s ease-in-out;
			}
		}
	}
}

@media (max-width: 1920px) {
	.logo-heder {
		padding-left: 125px;
	}
}

@media (min-width: 1200px) {
	.middle-section {
		.featured-section {
			.featured-box {
				.heading {
					h3 {
						font-size: 16px !important;
						line-height: 22px !important;
					}
				}
			}
		}

		.newest-box {
			.heading {
				h3 {
					font-size: 16px !important;
					line-height: 22px !important;
				}
			}
		}
	}
}

@media (max-width: 1024px) and (min-width: 768px) {
	.fixed-top {
		.logo-heder {
			position: fixed;
			padding-left: 0px;
			transform: translateY(0vh);
			left: 20px;
			top: 10px;
			transform-origin: top left;
			transition: all 0.3s ease-in-out;
			margin: unset;
			width: 100%;

			.container {
				max-width: 100% !important;
				width: auto;
			}

			a {
				img {
					width: 180px !important;
					height: 40px !important;
				}
			}
		}
	}


}

@media (max-width: 1400px) {
	.logo-heder {
		position: absolute;
		top: unset;
		left: unset;
		padding-left: 90px;
		width: 100%;
		margin: 0 auto;
		transform: translateY(15vh);

		a {
			padding: 0 !important;

			img {
				width: auto !important;
				height: 70px !important;
				transition: all 0.3s ease-in-out;
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.logo-heder {
		padding-left: 90px;
	}
}

@media (min-width: 1921px) and (max-width: 2560px) {
	.logo-heder {
		padding-left: 295px;
	}
}

@media (max-width: 1280px) {
	.logo-heder {
		position: absolute;
		top: unset;
		left: unset;
		padding-left: 125px;
		width: 100%;
		margin: 0 auto;
		transform: translateY(15vh);

		a {
			padding: 0 !important;

			img {
				width: auto !important;
				height: 60px !important;
				transition: all 0.3s ease-in-out;
			}
		}
	}
}

@media (min-width: 1000px) and (max-width: 1400px) {
	// .middle-section {
	// 	.hero-banner-section {
	// 		.item {
	// 			.slide-owl {
	// 				.banner-caption {
	// 					.caption-box {
	// 						margin-top: 20px;
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}

/* Home section */
.form-group {
	margin-bottom: 20px;

	label {
		font-size: 18px;
		color: #181818;
		letter-spacing: 0;
		font-weight: 400;
		margin-bottom: 10px;
	}

	.form-control {
		border: 1px solid #707070;
		height: 50px;
		background-color: #fff;
		border-radius: 0;
		font-size: 18px;
		box-shadow: none;
		outline: 0;

		&:focus {
			border-color: #1d61f6 !important;
			color: #000;
		}
	}
}

.middle-section {
	overflow-x: hidden;

	.row-10 {
		margin: 0 -10px;

		.pad-10 {
			padding: 0 10px;
		}
	}

	.hero-banner-section {
		min-height: max(75vh, 540px);
		height: max(75vh, 700px);
		max-height: 75vh;
		margin-top: 60px;

		@media (max-width: 767px) {
			min-height: 540px;
			height: 600px;
			.slider-container .dot {
				margin: 4px !important;
			}
			.slider {
				padding-top: 150px !important;
			}
		}

		width: 100%;

		.hero-banner-inner {
			min-height: 100%;
		}

		.container-fluid {
			width: 100%;
			padding: 0;
		}

		.item {
			height: 100%;
			min-height: 100vh;

			// .slide-owl {
			// 	padding: 10vh 0;
			// 	background-repeat: no-repeat;
			// 	background-size: cover;
			// 	background-position: center;
			// 	position: relative;
			// 	top: 0;
			// 	left: 0;
			// 	bottom: 0;
			// 	right: 0;
			// 	width: 100%;
			// 	min-height: 100vh;
			// 	height: 100%;
			// 	display: flex;
			// 	align-items: center;
			// 	justify-content: flex-start;
			// 	flex-direction: column;

			// 	.banner-caption {
			// 		display: flex;
			// 		align-items: center;
			// 		justify-content: center;
			// 		flex-direction: column;
			// 		min-height: max(90vh, 700px);
			// 		margin: 0 auto;
			// 		width: 100%;
			// 		z-index: 99;
			// 		position: relative;
			// 		padding-left: 140px;

			// 		.header-top {
			// 			position: fixed;
			// 			left: 20px;
			// 			top: 8px;
			// 			z-index: 99999;
			// 			transition: all 8s;

			// 			img {
			// 				width: 180px;
			// 				height: 40px;
			// 			}
			// 		}

			// 		.logo-header-caption {
			// 			margin-bottom: 20px;
			// 		}

			// 		.caption-box {
			// 			padding: 20px 20px;
			// 			background-color: rgba(0, 0, 0, 0.6);
			// 			width: 100%;
			// 			color: #fff;
			// 			font-family: "Montserrat", sans-serif;

			// 			h2 {
			// 				font-size: 42px;
			// 				line-height: 52px;
			// 				font-weight: bold;
			// 			}

			// 			h3 {
			// 				font-size: 16px;
			// 				line-height: 22px;
			// 				font-weight: 500;
			// 				margin-bottom: 20px;
			// 			}

			// 			p {
			// 				font-size: 16px;
			// 				line-height: 22px;
			// 				font-weight: normal;
			// 			}

			// 			.btn-launch {
			// 				background-color: #006586;
			// 				border-radius: 3px;
			// 				padding: 9px 32px;
			// 				color: #fff;
			// 				font-weight: 600;
			// 				font-size: 15px;
			// 				line-height: 20px;
			// 				letter-spacing: 0;
			// 			}
			// 		}
			// 	}

			// 	&:after {
			// 		position: absolute;
			// 		top: 0;
			// 		left: 0;
			// 		height: 350px;
			// 		width: 100%;
			// 		content: "";
			// 		background: linear-gradient(0deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 1) 100%);
			// 	}

			// 	&:before {
			// 		position: absolute;
			// 		bottom: 0;
			// 		left: 0;
			// 		height: 350px;
			// 		width: 100%;
			// 		content: "";
			// 		background: linear-gradient(0deg, rgba(34, 34, 34, 1) 0%, rgba(34, 34, 34, 0) 100%);
			// 	}
			// }
		}

		// .owl-nav {
		// 	position: absolute;
		// 	left: 0;
		// 	top: 40%;
		// 	width: 100%;
		// 	max-width: 1600px;
		// 	right: 0;
		// 	margin: 0 auto;

		// 	.owl-prev {
		// 		position: absolute;
		// 		left: 40px;
		// 	}

		// 	.owl-next {
		// 		position: absolute;
		// 		right: 40px;
		// 	}

		// 	button {
		// 		box-shadow: none;
		// 		outline: 0;
		// 		background-color: transparent !important;
		// 	}

		// 	.span-roundcircle {
		// 		background-color: rgba(0, 0, 0, 0.4);
		// 		height: 83px;
		// 		width: 83px;
		// 		display: flex;
		// 		align-items: center;
		// 		justify-content: center;
		// 		border-radius: 100%;
		// 		font-size: 20px;
		// 		color: #fff;
		// 		transition: all 0.3s ease-in-out;

		// 		&:hover {
		// 			background-color: rgba(0, 0, 0, 0.6);
		// 			transition: all 0.3s ease-in-out;
		// 		}
		// 	}
		// }

		// .owl-dots {
		// 	position: absolute;
		// 	left: 0;
		// 	right: 0;
		// 	bottom: 200px;

		// 	.owl-dot {
		// 		border: 0 !important;
		// 		outline: 0 !important;
		// 		box-shadow: none;

		// 		span {
		// 			color: rgba(255, 255, 255, 0.5);
		// 			width: 0.7em;
		// 			height: 0.7em;
		// 		}

		// 		&.active {
		// 			span {
		// 				color: #fff;
		// 			}
		// 		}
		// 	}
		// }
	}

	.featured-section {
		background-color: #1b1b1b;
		padding-bottom: 50px;

		.featured-inner {
			top: -35px;
			z-index: 99;
			position: relative;
		}

		.heading {
			h2 {
				font-size: 36px;
				line-height: 44px;
				font-weight: 700;
				font-family: $Montserrat;
				color: #fff;
				margin-bottom: 20px;
			}
		}

		.featured-box {
			background-color: rgba(0, 0, 0, 0.6);
			display: block;
			padding: 5px 10px 10px 10px;
			text-decoration: none;
			margin-bottom: 40px;

			.heading {
				h3 {
					font-size: 22px;
					line-height: 27px;
					font-weight: 600;
					color: #fff;
					font-family: $Montserrat;
					margin-bottom: 20px;
					text-decoration: none;
				}
			}

			.img-box {
				img {
					width: 100%;
					min-height: 230px;
					max-height: 230px;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			.video-ply-button {
				height: 100%;
				width: 100%;
				position: relative;

				&::before {
					content: "";
					position: absolute;
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					background-image: url(../images/play.png);
					background-repeat: no-repeat;
					background-size: auto;
					background-position: center;
				}
			}
		}

		.featured-slide {
			margin-right: 60px;
			vertical-align: middle;

			// .owl-nav {
			// 	display: block;
			// 	position: absolute;
			// 	right: -50px;
			// 	top: 0;
			// 	margin: 0;

			// 	button {
			// 		margin: 0;
			// 		width: 40px;
			// 		height: 292px;
			// 		display: flex;
			// 		align-items: center;
			// 		justify-content: center;
			// 		background-color: rgba(0, 0, 0, 0.2);
			// 		box-shadow: none;
			// 		outline: 0;
			// 	}

			// 	.owl-prev {
			// 		display: none;
			// 	}
			// }

			.active-none {
				padding: 0 !important;
			}

			.active-item {
				padding: 0 !important;
			}

			// .owl-nav.active {
			// 	padding-top: 0px;
			// }
		}

		.newest-box {
			background-color: rgba(0, 0, 0, 0.6);
			display: block;
			padding: 5px 10px 10px 10px;
			text-decoration: none;
			margin-bottom: 0px;

			.heading {
				display: flex;
				align-items: center;
				justify-content: space-between;

				h3 {
					font-size: 22px;
					line-height: 27px;
					font-weight: 600;
					color: #fff;
					font-family: $Montserrat;
					margin-bottom: 20px;
					text-decoration: none;
				}

				span {
					display: none;
					height: 18px;
					width: 18px;
					position: relative;
					top: -3px;
				}
			}

			.img-box {
				img {
					width: 100%;
					min-height: 230px;
					max-height: 230px;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}

				position: relative;

				.img-cornor {
					position: absolute;
					display: none;
					left: 0;
					bottom: 0;
					width: 124px;
					height: 40px;
					padding: 5px;
					background-color: rgba(0, 0, 0, 0.4);
					z-index: 99;

					img {
						min-height: unset !important;
						max-height: unset !important;
						width: 100%;
					}
				}
			}

			.video-ply-button {
				height: 100%;
				width: 100%;
				position: relative;

				&::before {
					content: "";
					position: absolute;
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					background-image: url(../images/play.png);
					background-repeat: no-repeat;
					background-size: auto;
					background-position: center;
				}
			}
		}

		.content-box {
			padding: 10px 0 5px 0;
			display: none;

			p {
				font-size: 10px;
				line-height: 14px;
				color: #fff;
				margin-bottom: 5px;
			}

			hr {
				border-bottom: 1px solid #333;
				margin: 0;
			}

			.card-footer {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					display: inline-block;
					padding: 0 8px 0 0;

					a {
						font-size: 9px;
						color: #fff;
						text-decoration: none;

						i {
							margin-right: 5px;
						}

						&:hover {
							color: #006586;
						}
					}
				}
			}

			.btn-launch {
				background-color: #006586;
				color: #fff;
				font-size: 9px;
				font-weight: 700;
				border-radius: 0;
				padding: 4px 8px;

				&:hover {
					color: #fff !important;
				}
			}
		}

		.newest-slide {
			margin-right: 60px;
			min-height: 550px;
			vertical-align: middle;

			// .owl-nav {
			// 	display: block;
			// 	position: absolute;
			// 	right: -50px;
			// 	top: 0;
			// 	margin: 0;

			// 	button {
			// 		margin: 0;
			// 		width: 40px;
			// 		height: 292px;
			// 		display: flex;
			// 		align-items: center;
			// 		justify-content: center;
			// 		background-color: rgba(0, 0, 0, 0.2);
			// 		box-shadow: none;
			// 		outline: 0;
			// 	}

			// 	.owl-prev {
			// 		display: none;
			// 	}
			// }
		}

		.featured-box.active {
			transform: scale(1.5);
			transition: all 0.5s;
			z-index: 99;
			background-color: #000;
			margin-bottom: 30px;

			.content-box {
				display: block;
			}

			.heading {
				h3 {
					font-size: 16px;
					line-height: 20px;
					font-weight: 600;
					color: #fff;
					font-family: $Montserrat;
					margin-bottom: 10px;
				}

				span {
					display: block !important;
				}
			}

			.img-box {
				img {
					width: 100%;
					min-height: 150px;
					max-height: 150px;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}

		.newest-box.active {
			transform: scale(1.5);
			transition: all 0.5s;
			z-index: 99;
			background-color: #000;

			.content-box {
				display: block;
			}

			.img-cornor {
				display: block;
			}

			.heading {
				h3 {
					font-size: 16px;
					line-height: 20px;
					font-weight: 600;
					color: #fff;
					font-family: $Montserrat;
					margin-bottom: 10px;
				}

				span {
					display: block !important;
				}
			}

			.img-box {
				img {
					width: 100%;
					min-height: 150px;
					max-height: 150px;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}

		.active-item {
			z-index: 999;
			position: relative;
			padding: 0px 0 0px 0;
			top: 0px;
		}

		.active-none {
			padding: 100px 0 80px 0;
		}

		// .owl-nav.active {
		// 	padding-top: 100px;
		// }

		// .owl-carousel .owl-stage-outer{overflow:visible;}
	}

	/* sign in section */
	select {
		/* inline SVG */
		background-image: url(../images/bg-arrow.png);
		background-position: right center;
		background-repeat: no-repeat;
		background-size: right 100%;
		border-radius: 2px;
		border: 1px solid #fff;
		outline: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
	}

	/* fliped box */
	.flipbox {
		.flipedbox {
			position: absolute;
			top: -50%;
			left: 90%;
			right: auto;
			min-width: 515px;
			width: 100%;
			height: auto;
			background-color: #000;
			border: 1px solid #000;
			display: none;
			transition: all 0.3s;

			.flipedbox-heading {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				padding: 5px 10px;

				.arrow-back {
					display: none;
				}

				.left {
					h2 {
						font-size: 22px;
						font-weight: bold;
						color: #fff;
						margin-bottom: 5px;
					}

					p {
						font-size: 11px;
						line-height: 15px;
						color: #fff;
						margin-bottom: 0;

						span {
							border-left: 1px solid #fff;
							color: #006585;
							margin-left: 5px;
						}
					}
				}

				.right {
					display: flex;
					align-items: center;
					padding: 10px 0;
				}

				span {
					color: #006585;
					font-size: 14px;
					line-height: 12px;
					padding: 0 10px;

					img {
						top: -3px;
						position: relative;
					}
				}
			}

			.flipedbox-body {
				.fliped-content-top {
					display: flex;
					align-items: flex-start;
					margin-bottom: 10px;
					padding-left: 10px;

					.fliped-img {
						width: 228px;
						min-width: 228px;
						height: 228px;

						img {
							width: 100%;
						}

						position: relative;

						.img-cornor {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 124px;
							height: 40px;
							padding: 5px;
							background-color: rgba(0, 0, 0, 0.4);
							z-index: 99;

							img {
								width: 100%;
							}
						}
					}

					.fliped-detail {
						padding: 0px 10px;

						p {
							color: #d6d6d6;
							font-size: 14px;
							line-height: 18px;
							margin-bottom: 0;
						}

						a {
							color: #006585;
							font-size: 14px;
							line-height: 18px;
							text-decoration: none;
						}

						.learn-share {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-bottom: 10px;

							ul {
								width: 100%;
								padding: 0;
								margin: 0;
								list-style: none;
								display: inline-block;

								li {
									display: inline-block;

									a {
										padding: 3px 8px;
										color: #006586;
										transition: all 0.3s;
										text-decoration: none;

										&:hover {
											color: #fff;
											transition: all 0.3s;
										}
									}
								}
							}

							.learmore-div {
								.btn-learnmore {
									background-color: #006586;
									color: #fff;
									padding: 5px 15px;
									white-space: nowrap;
									height: 40px;
									display: block;
									line-height: 30px;
									text-decoration: none;
									transition: all 0.3s;

									&:hover {
										background-color: #222;
										transition: all 0.3s;
									}
								}
							}
						}
					}
				}

				.fliped-content-middle {
					border-bottom: 1px solid #333;
					border-top: 1px solid #333;
					padding: 10px 0 0 0px;
					display: flex;
					align-items: flex-start;
					justify-content: center;
					flex-direction: row;
					margin-bottom: 5px;

					.left {
						text-align: center;
						display: block;
						width: 100%;
						padding-left: 5px;
						border-right: 1px solid #333;

						.lession-info {
							margin: 0;
							padding: 0;
							list-style: none;
							text-align: left;
							display: inline-block;

							li {
								display: inline-block;
								width: calc(50% - 5px);
								padding: 5px 5px 5px 5px;
								vertical-align: top;

								a {
									font-size: 12px;
									line-height: 12px;
									color: #006585;
									text-align: left;
									display: block;
									position: relative;
									padding-left: 20px;
									width: 100%;
									word-break: break-all;
									text-decoration: none;

									i {
										position: absolute;
										left: 0px;
										top: 0px;
									}
								}

								a.color-white {
									color: #c2c2c2;
								}
							}
						}
					}

					.right {
						text-align: center;
						display: block;
						width: 100%;
						padding-left: 5px;

						.lession-resource {
							margin: 0;
							padding: 0;
							list-style: none;
							text-align: left;
							display: inline-block;

							li {
								display: inline-block;
								width: calc(100% - 0px);
								padding: 5px 5px 5px 5px;
								vertical-align: top;

								a {
									font-size: 12px;
									line-height: 12px;
									color: #006585;
									text-align: left;
									display: block;
									position: relative;
									padding-left: 20px;
									width: 100%;
									word-break: break-all;
									text-decoration: none;

									i {
										position: absolute;
										left: 0px;
										top: 0px;
									}
								}

								a.color-white {
									color: #c2c2c2;
								}
							}
						}
					}

					h3 {
						font-size: 14px;
						color: #fff;
						line-height: 15px;
						font-weight: 700;
						text-align: center;
					}
				}

				.fliped-topic {
					padding: 5px 10px;

					h3 {
						font-size: 11px;
						line-height: 15px;
						color: #fff;
						font-weight: 700;
					}

					.topic-list {
						margin: 0;
						padding: 0;
						list-style: none;

						li {
							display: inline-block;
							margin-right: 10px;
							margin-bottom: 5px;

							.tag {
								padding: 3px 10px;
								background-color: #333;
								border: 1px solid #666;
								border-radius: 10px;
								color: #fff;
								font-size: 11px;
								text-decoration: none;
								position: relative;
							}

							.tag-add {
								padding-right: 30px;

								&::after {
									content: "\f055";
									position: absolute;
									font-family: Font awesome\5 Free !important;
									-webkit-font-smoothing: antialiased;
									display: inline-block;
									font-style: normal;
									font-variant: normal;
									text-rendering: auto;
									line-height: 1;
									color: #fff;
									font-weight: 600;
									right: 5px;
									font-size: 12px;
									top: 5px;
								}
							}
						}
					}
				}
			}

			#text_hide_show {
				display: none;
			}
		}

		.flipedbox.active {
			display: block;
			transition: all 0.3s;
		}
	}

	.flipedbox.active {
		display: block;
	}
}

@media (max-width: 767px) {
	.middle-section {
		.hero-banner-section {
			.item {
				// .slide-owl {
				// 	.banner-caption {
				// 		padding-left: 0;

				// 		.caption-box {
				// 			h2 {
				// 				font-size: 26px;
				// 				line-height: 34px;
				// 				font-weight: 600;
				// 			}
				// 		}
				// 	}
				// }
			}
		}

		.featured-section {

			.featured-box {
				.heading {
					h3 {
						font-size: 16px;
						line-height: 24px;
						min-height: 48px;
					}
				}

				.video-ply-button::before {
					background-size: 20%;
				}
			}

			.heading {
				h2 {
					font-size: 32px;
					line-height: 36px;
				}
			}

			.newest-box {
				.heading {
					h3 {
						font-size: 16px;
						line-height: 24px;
						min-height: 48px;
					}
				}
			}

			.featured-slide {
				margin-right: 0;

				.img-box {
					img {
						min-height: auto;
						max-height: unset;
					}
				}

				// .owl-nav button.owl-next {
				// 	display: none;
				// }
			}

			.newest-slide {
				margin-right: 0;

				// .owl-nav button.owl-next {
				// 	display: none;
				// }

				.img-box {
					img {
						min-height: auto;
						max-height: unset;
					}
				}


			}
		}

		.content-libary-section .select-short {
			margin-bottom: 20px;

			.form-control {
				max-width: 100%;
			}
		}

		/* footer */
		.content-libary .featured-box {
			margin-bottom: 20px;
		}

		.content-libary .featured-box .img-box img {
			max-width: unset;
			min-height: auto;
		}

		/* fliped box */
		.flipbox {
			.flipedbox.removed {
				display: none !important;
			}

			.flipedbox {
				position: fixed;
				left: 0;
				top: 60px;
				height: calc(100vh - 60px);
				z-index: 99;
				min-width: unset;
				width: 100%;
				overflow-x: scroll;
				padding-bottom: 60px;

				.flipedbox-heading {
					justify-content: flex-start;
					padding: 0 35px;

					.arrow-back {
						display: flex;
						align-items: center;
						justify-content: center;
						color: #fff !important;
						width: 50px;
						height: 50px;
						text-decoration: none;
						position: absolute;
						left: 0;
						top: 0;
					}

					.left {
						width: 100%;
						text-align: center;
					}

					.right {
						display: none;
					}
				}

				.flipedbox-body {
					.fliped-content-top {
						display: flex;
						flex-direction: column;
						padding-left: 0;

						.fliped-img {
							height: unset;
							width: 100%;
							padding: 10px;

							.img-cornor {
								left: 10px;
								bottom: 10px;
							}
						}
					}

					.fliped-content-middle {
						display: flex;
						flex-direction: column;

						.left {
							border-right: 0px solid #333;
							border-bottom: 1px solid #333;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}
}

/* form-control error*/
.form-control.is-invalid {
	border-color: #dc3545 !important;
	border-width: 3px;
	background-color: rgb(220 53 69 / 0.1) !important;
	color:  #717171;
}

// .owl-theme .owl-nav [class*="owl-"] {
// 	background: none !important;
// }

.addTagModal .mat-dialog-container {
	border-radius: 0px !important;
	padding: 0px !important;
}


// .owl-item {
// 	display: none;

// 	&:not(.active) {
// 		display: block;
// 	}
// }

a {
	color: #2398b8;
}

div.lesson-item-description {
	p {
		margin: 0px;
	}
}

div#pint-main> {
	span {
		max-width: 100% !important;
		height: 520px;
	}
}

div#fb-main> {
	span {
		max-width: 100% !important;
		height: 280px;
	}
}

@media (min-width: 320px) and (max-width: 767px) {
	.row.comm_main> {
		.col-md-8 {
			margin-bottom: 30px;
		}
	}
}

// slider overlay center button
.slider-overlay-center-btn {
	background-color: #006586;
	color: #fff;
	font-size: 13px;
	z-index: 10;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

#overlay {
	position: absolute;
	display: block;
	width: 100%;
	height: 520px !important;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 2;
	cursor: pointer;
	border-radius: 15px !important;
}

#fb-overlay {
	position: absolute;
	display: block;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 2;
	cursor: pointer;
}

.facebook-responsive {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.facebook-responsive iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

.spacer {
	padding-top: 5px;
	padding-bottom: 5px;
}

.hide-label {
	visibility: hidden;
	height: 0;
	position: absolute;
}

.grade-text {
	font-size: 18px;
	line-height: 24px;
	font-weight: 800;
	margin-bottom: 5px;
}


*:focus{
  outline:solid 0px !important;
  box-shadow: 0 0 0 2.5px #99CBDC, 0 0 0 3.5px #333, 0 0 0 5px #99CBDC !important;
  transition:all .3s;
}

.blogger {
	.separator {
		img {
			width: 100%;
			height: auto;
			padding: 10px;
		}
	}
	span {
		font-size: 15px !important;
		color: #757575;
	}
}


.fb_iframe_widget span {
	color: #757575 !important;
}

a.modal-link {
	text-decoration: underline;
	color: #1d7f9a;
}

a {
	text-decoration: underline;
}

	input::placeholder,
select::placeholder,
textarea::placeholder{
		color: #6c6c6c !important;
	}


.text-link {
	color: #3398B9 !important;
}

.user-signin {
	z-index: 999999 !important;
}

.modal {
	overflow: auto;
}

.icon-row  {
		&.bottom {
			position: relative !important;
			bottom: auto !important;
		}
}

.mobile-card {
	height: auto !important;
	margin-bottom: 60px;
	overflow-y: scroll !important;
	position: absolute !important;
	left: 0 !important;
	width: 100vw !important;
	overflow-x: hidden !important;
	padding-bottom: 10px !important;
}

.lessonContainer {
	padding-bottom: 80px;
}

#toggle {
	&:focus {
		outline:solid 0px !important;
		box-shadow: 0 0 0 2.5px #99CBDC, 0 0 0 3.5px #333, 0 0 0 5px #99CBDC !important;
		transition:all .3s;
	}
}

.educator.focused, .user.focused .user-signin, .tools.focused .user-signin {
	outline:solid 0px !important;
		box-shadow: 0 0 0 2.5px #99CBDC, 0 0 0 3.5px #333, 0 0 0 5px #99CBDC !important;
		transition:all .3s;
}


.slider {
	visibility: hidden;
	&.active {
		visibility: visible;
	}
}

.btn.launch {
	display: block;
	width: auto;
}

button.flipCard {
	background: transparent !important;
}

button.minimetric {
	background: transparent !important;
	i {
		color: #3398B9 !important;
	}
}

.tools {
	a {
		text-decoration: none !important;
	}
}

.skipnav {
	position: absolute;
	top: 0;
	left: 0;
	color: transparent;
	pointer-events: none;
	width: 30px;
	height: 30px;
}

.btn:disabled {
	opacity: 1 !important;
}

.learn-card {
	button {
		white-space: normal !important;
	}
}

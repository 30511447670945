
@media (max-width: 767px) {
    // .middle-section .featured-section .featured-slide .owl-nav .owl-next {
    //     display: none !important;
    // }
    // .middle-section .featured-section .newest-slide .owl-nav .owl-next {
    //     display: none !important;
    // }
    // .middle-section .featured-section .newest-slide .owl-dots {
    //     display: none;
    // }

    .middle-section .featured-section .featured-slide .img-box img {
        min-height: 95px;
        max-height: auto;
    }
    .middle-section .featured-section .newest-slide .img-box img {
        min-height: 95px;
        max-height: auto;
    }
}


/* nav */

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-height: 1366px) and (min-width: 1024px) and (orientation: portrait) {
    .sidenav-new-menu {
        padding: 70px 0 0 0 !important;
    }
}

/* featured box */

.middle-section .featured-section .featured-box {
    margin-bottom: 0px;
}

// .featured-slide .owl-carousel.owl-drag .owl-item {
//     padding: 0px !important;
//     min-height: unset !important;
// }

// .featured-slide .owl-theme .owl-nav .owl-next,
// .featured-slide .owl-theme .owl-nav .owl-prev {
//     padding-top: 0 !important;
// }

// .owl-carousel.owl-drag .owl-item {
//     padding: 0px !important;
// }

// .newest-slide .owl-carousel.owl-drag .owl-item {
//     margin: 100px 0 100px !important;
// }
.middle-section .featured-section .heading.newest-heading h2 {
    position: absolute;
    top: 30px;
    height: 0px;
    margin-bottom: 0 !important;
}

.middle-section .featured-section {
    padding-bottom: 70px;
    // margin-top: 5em;
}

.middle-section .featured-section.search-filter-pad {
    padding-bottom: 0;
    margin-top: 0em;
}

/*  */
.middle-section .featured-section .newest-slide {
    min-height: 580px;
}

// .hero-banner-section .owl-theme .owl-nav .owl-next,
// .hero-banner-section .owl-theme .owl-nav .owl-prev {
//     padding-top: 0 !important;
// }


// Mobile
@media (max-width: 767px) {
    .middle-section {
        .hero-banner-section {
            // min-height: unset;
            // height: auto;
                .hero-banner-inner{
                    min-height: unset;
                    .item {
                        min-height: unset;
                        // .slide-owl {
                        //     padding: 11vh 0;
                        //     min-height: 452px;
                        //     .banner-caption {
                        //         height: auto;
                        //         min-height: unset;
                        //     }
                        // }
                    }
                    // .owl-dots {
                    //     bottom: 40px;
                    // }
                }
        }
    }
    // banner

    // .newest-slide .owl-carousel.owl-drag .owl-item {
    //     padding: 0 !important;
    //     margin: 100px 0 120px !important;
    // }

    .middle-section .featured-section {
        padding-bottom: 0;
        margin-top: 0em;
    }
}

@media (min-width: 1300px) and (max-width: 1439px) {
    .container {
        max-width: 1360px;
        width: 100%;
    }

    // .middle-section .hero-banner-section .item .slide-owl .hero-container-box {
    //     padding: 8px 90px 0 200px !important;
    // }


}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    // .middle-section .hero-banner-section .item .slide-owl .hero-container-box {
    //     padding: 0 90px 0 200px !important;
    // }
}

@media (max-width: 1600px) and (min-width: 1025px) {
    // .owl-item.active:hover .item .pad-10 .newest-box {
    //     /* transform: scale(1.42); */
    //     padding: 5px 8px 10px;
    // }

    .middle-section {
        .featured-section {
            .content-box {

                ul {
                    li {
                        padding: 0 6px 0 0;
                        a {
                            font-size: 9px;
                            display: flex;
                            align-items: center;
                            i {
                                margin-right: 4px;
                            }
                        }
                    }
                }

                .btn-launch {
                    font-size: 8px;
                    padding: 4px 5px;
                }
                p {
                    font-size: 10px;
                    line-height: 13px;
                }
                ul {
                    &:nth-child(2) {
                        li {

                            &:last-child {
                                padding: 0;
                            }
                        }
                    }
                }
                .card-footer {
                    flex-wrap: wrap;
                }
            }
        }
    }
}

// .owlnext-custom {
//     margin: 0;
//     width: 40px;
//     height: 292px;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//     background-color: rgba(0, 0, 0, 0.2);
//     -webkit-box-shadow: none;
//     box-shadow: none;
//     outline: 0;
// }

.background {
    -webkit-animation-duration: 1s !important;
    -webkit-animation-fill-mode: forwards !important;
    -webkit-animation-iteration-count: infinite !important;
    -webkit-animation-name: placeHolderShimmer !important;
    -webkit-animation-timing-function: linear !important;
    background: #fff !important;
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%) !important;
    background-repeat: no-repeat !important;
    background-size: 800px 104px !important;
    height: 110px !important;
    position: relative !important;
}
.height-parent {
    height: 104px !important;
}
/*
section{
  padding: 12px;
  max-width: 476px;
  height: 300px;
  margin: 4rem auto;
  background-color: #fff;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: 3px;

}*/
.layout-loader {
    margin: 6px 0 6px 0 !important;
}
.background div {
    background: #fff;
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
}
div.a {
    height: 40px;
    left: 40px;
    right: auto;
    top: 0;
    width: 8px;
}

div.b {
    height: 8px;
    left: 48px;
    top: 0;
}
div.c {
    left: 136px;
    top: 8px;
}
div.d {
    height: 12px;
    left: 48px;
    top: 14px;
}
div.e {
    left: 100px;
    top: 26px;
}
div.f {
    height: 10px;
    left: 48px;
    top: 32px;
}
div.g {
    height: 20px;
    top: 40px;
}
div.h {
    left: 410px;
    top: 60px;
}
div.i {
    height: 13px;
    top: 66px;
}
div.j {
    left: 440px;
    top: 79px;
}
div.k {
    height: 13px;
    top: 85px;
}
div.l {
    left: 178px;
    top: 98px;
}

.background-self {
    -webkit-animation-duration: 1s !important;
    -webkit-animation-fill-mode: forwards !important;
    -webkit-animation-iteration-count: infinite !important;
    -webkit-animation-name: placeHolderShimmer !important;
    -webkit-animation-timing-function: linear !important;
    background: #f6f7f9 !important;
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%) !important;
    background-repeat: no-repeat !important;
    background-size: 800px 104px !important;
    position: relative !important;
}
.background-self-border {
    border: 1px solid #ddd;
}

.bs-center {
    text-align: center;
    margin: 0 auto;
}

.bs-page-header-center {
    text-align: center;
    width: 300px;
    margin: 0 auto;
}
.bs-page-sub-header-left {
    width: 300px;
    height: 30px;
}
.margin-bottom-10 {
    margin-bottom: 10px !important;
}
.margin-top-10 {
    margin-top: 10px !important;
}
.margin-top-botom-15 {
    margin: 15px 0px 15px 0px;
}
.border-right0 {
    border-right: 0 !important;
}
.hw25x100 {
    height: 25px;
    width: 100px;
}

.hw20x60 {
    height: 20px;
    width: 60px;
}
.hw50x150 {
    height: 50px;
    width: 150px;
}

.hw10x200 {
    height: 10px;
    width: 200px;
}
.hw20x200 {
    height: 20px;
    width: 200px;
}
.hw10xfull {
    height: 10px;
    left: 0px;
    right: auto;
}
.hw30xfull {
    height: 30px;
    width: 100%;
}
.hw20xfull {
    height: 20px;
    width: 100%;
}
.hw10xhalf {
    height: 10px;
    left: 0px;
    width: 50%;
}
.height200 {
    height: 200px;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

// .owl-theme .owl-nav .owl-prev,
// .owl-theme .owl-nav .owl-next {
//     padding-top: 100px !important;
//     margin: 0 !important;
// }

@media (max-width: 767px) {
    .show-in-mobile {
        display: block !important;
    }
    .show-in-web {
        display: none !important;
    }

    // .owl-carousel.owl-drag .owl-item {
    //     padding: 150px 0 120px !important;
    // }
}
@media (min-width: 768px) {
    .show-in-mobile {
        display: none !important;
    }
    .show-in-web {
        display: block !important;
    }
}

// changes
// .owl-item.active:hover {
//     z-index: 999;
// }
// .owl-item.active:hover .item .pad-10 .newest-box {
//     -webkit-transform: scale(1.5);
//     transform: scale(1.5);
//     -webkit-transition: all 0.5s;
//     transition: all 0.5s;
//     z-index: 99;
//     background-color: #000;
// }
// .owl-item.active:hover .item .pad-10 .content-box {
//     display: block;
// }

// .owl-item.active:hover .item .pad-10 .newest-box .heading h3 {
//     font-size: 16px;
//     line-height: 20px;
//     font-weight: 600;
//     color: #fff;
//     font-family: "Montserrat", sans-serif;
//     margin-bottom: 10px;
// }

// .owl-item.active:hover .item .pad-10 .newest-box .heading span {
//     display: block !important;
// }

// .owl-item.active:hover .item .pad-10 .newest-box .img-cornor {
//     display: block;
// }

// .owl-item.active:hover .item .pad-10 .newest-box .img-box img {
//     width: 100%;
//     min-height: 150px;
//     max-height: 150px;
//     height: 100%;
//     -o-object-fit: cover;
//     object-fit: cover;
//     -o-object-position: center;
//     object-position: center;
// }
.middle-section .featured-section .newest-slide {
    min-height: 550px;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

@mixin montserrat-family { font-family: 'Montserrat', sans-serif; }
@mixin open-sans-family { font-family: 'Open Sans', sans-serif; }

/* Improvement in Header */



/* End of Improvement in Header */

/* header 2 */

/* Improvement in Slider */



/* End of Improvement in Slider */

/*  Start educator  */

.community-row{ position: relative; }

.mr-20{ margin-right: 20px !important; }
.btn.btn-link { font-weight: 400; color: #FFFFFF; background-color: transparent; text-decoration: none; font-size: 16px; line-height: 22px; padding: 0; @include open-sans-family();transition: all 0.8s; text-decoration: underline; }
.btn.btn-join { background-color: #FCBB42; color: #1C2430; font-weight: 700; font-size: 15px;    line-height: 20px; @include open-sans-family();  padding: 9px 10px; transition: all 0.8s;
    &:hover, &:focus{  background-color: #e6ab3d; }
}

.common-title-header-div{
    padding: 0; margin: 0; position: relative;
    .heading-right{  display: flex; justify-content: flex-end; flex-wrap: wrap; margin: 0 0 20px 0; }
}

.common-post-event-root { background-color: rgba(0, 0, 0, 0.6); padding: 20px 20px 0px 20px; margin: 0 0 40px 0;
    .mlr-8{ margin-right: -8px; margin-left: -8px; }
    .plr-8{ padding-right: 8px; padding-left: 8px; }
}

.post-event-card-box { padding: 5px 10px; margin: 0 0 20px 0; background-color: #222;
    h3{ font-size: 22px; line-height: 25px; font-weight: 600; color: #fff; font-family: "Montserrat", sans-serif; margin-bottom: 5px; text-decoration: none; min-height: 50px; }
    .prag-text{
        p { font-size: 18px; line-height: 22px; margin: 0 0 10px 0;  color: #EBEBEB; @include open-sans-family();min-height: 70px; }
    }
    .bottom-text-row{
        p { font-size: 13px; line-height: 20px; margin: 0 0 2px 0; color: #EBEBEB; @include open-sans-family(); }
        p.time-text { color: #999999; }
    }

    .bottom-down-text {
        display: flex; justify-content: space-between; flex-wrap: wrap;
        .bottom-down-text-right { display: flex; flex-wrap: wrap; }
        .icon-box { font-size: 11px; padding: 0 6px; color: #ADADAD;
            a{ color: inherit; text-decoration: none; }
            i { font-size: 14px; margin: 0 2px 0 0; line-height: 18px; }
            &:hover{
                color: #006586;
            }
        }
    }

}

.fixed-bottom-right { position: fixed; bottom: 20px; right: 20px; z-index: 999; }

.btn-submit-lesson{
    background-color: #FFFFFF; min-height: 60px; font-size: 18px; line-height: 20px; font-weight: 700; border: 2px solid transparent;
    @include open-sans-family(); color: #006586; border-radius: 100px; box-shadow: 0 0 10px 0 #000; display: flex; align-items: center; flex-wrap: wrap; padding: 10px;
    span.round-plus { height: 40px; width: 40px; display: flex; align-items: center; justify-content: center; background-color: #006586; color: #FFFFFF; font-size: 30px; font-weight: 700; border-radius: 100px; margin: 0 10px 0 0; @include montserrat-family(); }
    span.text-span { max-width: 130px; word-break: break-word; display: inline-block; text-align: left; }
    &.disabled{
        background-color: #333; color: #C2C2C2; opacity: 1; border-color: rgba(255, 255, 255, 0.2);     cursor: not-allowed;
        span.round-plus{ background-color: #C2C2C2; color: #333; }
    }
    &:hover, &:focus{
        outline: none;
        box-shadow: 0 0 10px 0 #000 !important;
    }
}

/*  End of educator  */

/* 21-08-2020 **/

.modal-card-custom-div{
    .modal-dialog{ max-height: calc(100% - 110px); margin: 60px auto 0 auto; }
    .modal-dialog-scrollable .modal-content { max-height: calc(100vh - 110px); overflow: hidden; }
    .modal-content{ background-color: rgba(0, 0, 0, 1); background-clip: padding-box; border: 0px solid rgba(0,0,0,.2); border-radius: 0; }
    .modal-body{ overflow-y: auto; padding: 0; min-height: calc(100vh - 110px); }
}

.card-box-common {
    background-color: rgba(0, 0, 0, 1); display: block; padding: 0px; text-decoration: none; margin-bottom: 0px;

    .heading-top-div { display: block; padding: 5px 10px;
        .heading-top-row { width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap; position: relative; min-height: 30px; }
        h3 { font-size: 16px; line-height: 24px; font-weight: 600; color: #fff; font-family: 'Montserrat', sans-serif; margin-bottom: 0; text-decoration: none; text-align: center; word-break: break-word; }
        span.flip-btn { display: block; height: 18px; width: 18px; position: absolute; top: 1px; right: 0; }

        .left-heading-div { width: 30px; display: flex; align-items: center; justify-content: center; }
        .btn-back.btn-arrow { color: #fff; font-size: 14px; line-height: 24px; }

        .center-div { width: calc(100% - 30px); display: flex; align-items: center; justify-content: center; padding: 0 30px 0 0; }

    }

    .card-box-common-body{
        padding: 10px;
    }

    .img-box{
        position: relative; margin: 0 0 10px 0;
        img { width: 100%; height: 230px; object-fit: cover; object-position: center; }
        .img-cornor{ position: absolute; display: block; left: 0; bottom: 0; width: 124px; height: 40px; padding: 5px; background-color: rgba(0, 0, 0, 0.4); z-index: 99;
            img { height: 30px !important; width: 100%; object-fit: contain; object-position: center; }
        }
    }

    .btn-common-primary { background-color: #006586;
        color: #fff; font-size: 14px; font-weight: 700; border-radius: 0; padding: 5px 10px; width: 100%;
        &:hover { color: #fff !important; }
    }

    .video-ply-button { height: 100%; width: 100%; position: relative;
        &::before { content: ''; position: absolute; height: 100%; width: 100%; top: 0; left: 0; display: flex; align-items: center; justify-content: center; background-image: url(../images/play.png); background-repeat: no-repeat; background-size: auto; background-position: center; }
    }

    .content-box{
        display: block; padding: 10px 0 5px 0;
        p{ font-size: 14px; line-height: 20px; color: #fff; margin-bottom: 10px; margin-top: 0; }
        hr { border-bottom: 1px solid #333; margin: 0; }
    }

    .top-div-body { min-height: calc(100vh - 110px - 50px - 60px); display: block; position: relative; }
    .bottom-div-body { min-height: 50px; display: block; position: relative; }

    .card-footer{ display: flex; align-items: center; justify-content: space-between; padding: 0;
        .icon-list-ul { margin: 0; padding: 0; list-style: none; display: flex; justify-content: space-between; flex-wrap: wrap; width: 100%;
            li { display: inline-block; padding: 0 8px 0 0;
                a { font-size: 14px; color: #fff; text-decoration: none;
                    i { margin-right: 5px; }
                    &:hover { color: #006586; }
                }
            }
        }
    }



}


/* End of 21-08-2020 **/




@media (min-width: 1025px){


    // .middle-section {
    //     .hero-banner-section {
    //         .owl-nav { max-width: 1440px; top: 45%;
    //             .owl-prev{ left: 45px; }
    //             .owl-next{ right: 45px; }
    //         }
    //         .item {
    //             .slide-owl {
    //                 .banner-caption { padding-left: 0px;
    //                     .caption-box{ margin-left: 0px; max-width: 680px; }
    //                 }
    //                 .hero-container-box {
	// 										padding: 0 90px 0 160px;
	// 										@media (max-width: 1200px) {
	// 											padding: 0 110px;
	// 										}
	// 										@media (max-width: 1140px) {
	// 											padding: 0 110px 0 180px;
	// 										}
	// 									}
    //             }
    //         }
    //     }
    // }

}



/* End of Improvement in Slider */




@media (max-width: 767px){


    .middle-section .featured-section .featured-inner { top: -40px; }


    // .middle-section .hero-banner-section .item .slide-owl .banner-caption .caption-box {
    //     h3{ font-size: 14px; line-height: 18px; }
    //     p { font-size: 14px; line-height: 18px; }
    // }

    .user-drop-down .dropdown .btn-secondary .img-user { height: 30px; width: 36px; min-width: 36px;}

    .header-color2 .sidenav-new-menu .closebtn{ background-color: #006586; }

    /*  start educator  */

    .common-post-event-root { padding: 20px 10px 0px 10px; margin: 0 0 30px 0; }

    .btn-submit-lesson { min-height: 48px; font-size: 14px; line-height: 16px; padding: 5px;
        span.text-span { max-width: 100px; }
        span.round-plus { height: 32px; width: 32px; font-size: 24px; margin: 0 5px 0 0; }
    }

    .common-title-header-div .heading-right{  justify-content: flex-start; }

    .fixed-bottom-right { bottom: 60px; right: 10px; }

    /*  End of educator  */


    .post-event-card-box h3{
        font-size: 18px;
        line-height: 24px;
    }

    .post-event-card-box .prag-text p {
        font-size: 16px;
    }

    .login-featured-section .community-row {
        padding: 10px !important;
        margin-bottom: 30px;
    }
    .button-group-box {
        margin-bottom: 10px;
    }

    .common-title-header-div .heading-right {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) and (max-width: 1024px){

    .middle-section .featured-section .featured-inner { top: -50px; }
    // .middle-section .hero-banner-section .owl-nav .owl-prev { left: 10px; }
    // .middle-section .hero-banner-section .owl-nav .owl-next{ right: 10px; }
    // .middle-section .hero-banner-section .item .slide-owl .banner-caption { padding-left: 0; }
    // .middle-section .hero-banner-section .owl-nav { top: 45%; }
    .hero-container-box { padding: 0 90px 0 90px; }

    // .middle-section {
    //     .hero-banner-section {
    //         .item {
    //             .slide-owl {
    //                 .banner-caption {
    //                     .caption-box{ margin-left: 20px; max-width: 680px; }
    //                 }
    //             }
    //         }
    //     }
    // }

    .header-color2 .sidenav-new-menu .closebtn{ background-color: #006586; }

    .btn-submit-lesson { min-height: 48px; font-size: 14px; line-height: 16px; padding: 5px;
        span.text-span { max-width: 100px; }
        span.round-plus { height: 32px; width: 32px; font-size: 24px; margin: 0 5px 0 0; }
    }


    .featured-section {
        .featured-inner {
            top: 0em !important;
            padding-top: 2em;
            padding-bottom: 2em;
        }
    }

}

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: portrait){

    .logo-heder { transform: translateY(20vh); }
    .logo-heder { padding-left: 45px; }
    // .middle-section {
    //     .hero-banner-section {
    //         .item {
    //             .slide-owl {
    //                 .banner-caption {
    //                     .caption-box{ margin-left: 0px; max-width: 600px; }
    //                 }
    //             }
    //         }
    //     }
    // }


    // .middle-section .hero-banner-section .owl-nav {
    //     top: 47%;
    // }
    .user-drop-down .dropdown .btn-secondary::after {
        right: -30px;
    }

}

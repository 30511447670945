
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$infiniscope-website-primary: mat-palette($mat-indigo);
$infiniscope-website-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$infiniscope-website-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$infiniscope-website-theme: mat-light-theme((
  color: (
    primary: $infiniscope-website-primary,
    accent: $infiniscope-website-accent,
    warn: $infiniscope-website-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($infiniscope-website-theme);

:root {
	--color-primary: #006585;
	--color-primary-vivid: #009ab6;
	--color-primary-darker: #0e1721;
  	--color-secondary: blue;
	--color-edit: #77dd77;
	--color-black: black;
	--color-dark-grey: #333;
	--color-faded: #999999;
	--color-faded-lightish: #e8e8e8;
	--color-faded-light: #d6d6d6;
	--color-faded-lighter: #ebebeb;
  	--color-dark: rgba(0, 0, 0, 0.6);
	--color-darkish: rgba(0, 0, 0, 0.2);
	--color-yellow: #FCBB42;
	--color-flip-card: #3398B9;
	--color-submit-lesson-disabled: #C2C2C2;

	--font-regular: "open_sansregular";
	--font-bold: "open_sansbold";
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
